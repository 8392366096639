/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import * as React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { Provider } from 'react-redux'
// Sentry
import * as Sentry from '@sentry/react'
// Use consistent styling
import 'sanitize.css/sanitize.css'
// Import root app
import { App } from 'app'
import { HelmetProvider } from 'react-helmet-async'
import { configureAppStore } from 'store/configureStore'
import reportWebVitals from 'reportWebVitals'

const store = configureAppStore()
const MOUNT_NODE = document.getElementById('root') as HTMLElement

Sentry.init({
  dsn: 'https://114fe59cb5d17986076b5badb6583cbf@o4506029489258496.ingest.us.sentry.io/4507524044095488',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  tracePropagationTargets: ['localhost', /^https:\/\/app\.carmax\.com.ph/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

ReactDOMClient.createRoot(MOUNT_NODE!).render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </HelmetProvider>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
